import React from 'react'
import './Loader.css'

const Loader = ({ position = 'fixed' }: { position?: string }) => {
  return (
    <div className={`loading-modal ${position || ''}`}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loader
