'use client'
import PropTypes from 'prop-types'
import React, { ChangeEvent, useState } from 'react'
import style from './FormInputFile.module.scss'
import FormGroup from '@/components/forms/FormGroup'
import { Label } from '@/components/forms/Label'
import InputGroup from '@/components/forms/InputGroup'
import { classNames } from '@/components/shared/classNames'
import InputErrorText from '@/components/forms/InputErrorText'
import FileInput from './FileInput'

interface FormInputFileProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string | React.ReactNode
  placeholder: string
  value: string | number
  isValid?: boolean
  errorText?: string
  required?: boolean
  resetField: any
  onChange: (event: ChangeEvent<HTMLInputElement>) => any
}

const FormInputFile = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      value,
      isValid = true,
      required,
      className,
      errorText,
      resetField,
      onChange
    }: FormInputFileProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event)
    }

    return (
      <FormGroup className={className}>
        <Label isFocused={isFocused} isValid={isValid} hidden={Boolean(label)} htmlFor={name}>
          {label}
        </Label>
        <InputGroup>
          <FileInput
            className={classNames(style.input, isValid ? '' : style['input--invalid'])}
            name={name}
            required={required}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleOnChange}
            resetField={resetField}
            placeholder={isFocused ? '' : placeholder}
          />
          <InputErrorText>{errorText}</InputErrorText>
        </InputGroup>
      </FormGroup>
    )
  }
)

FormInputFile.displayName = 'FormInputFile'
export default FormInputFile
