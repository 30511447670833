import { SweetAlertOptions } from 'sweetalert2'

const baseConfig: SweetAlertOptions = {
  position: 'center',
  icon: 'success',
  title: '¡Operacion exitosa!',
  showConfirmButton: false,
  timer: 2500
}
const success: SweetAlertOptions = {
  ...baseConfig,
  icon: 'success'
}
const error: SweetAlertOptions = {
  ...baseConfig,
  icon: 'error'
}

const successNewsLetter: SweetAlertOptions = {
  ...success,
  title: '¡Te has suscrito a nuestro newsletter exitosamente!'
}

const errorNewsLetter: SweetAlertOptions = {
  ...error,
  title: '¡Ha ocurrido un error al suscribirte a nuestro newsletter! Intenta más tarde'
}

const errorContactForm: SweetAlertOptions = {
  ...error,
  title: '¡Ha ocurrido un error al enviar tu mensaje! Intenta más tarde'
}

const errorCourseForm: SweetAlertOptions = {
  ...error,
  title: '¡Ha ocurrido un error al enviar tu información! Intenta más tarde'
}

const errorBaseContactForm: SweetAlertOptions = {
  ...error,
  title: '¡Ha ocurrido un error al enviar tu mensaje! Intenta más tarde'
}

export { successNewsLetter, errorNewsLetter, errorContactForm, errorCourseForm, errorBaseContactForm }
