export interface ISelectOption {
  value: string
  label: string
}

export interface IInputConfiguration {
  type: string
  subtype: string
  defaultValue?: string | number | boolean
  name: string
  label: string
  className: string
  placeholder: string
  required: boolean
  showLabel: boolean
  validations: {
    required?: { value: boolean; message: string }
    minLength?: { value: number; message: string }
    maxLength?: { value: number; message: string }
    pattern?: { value: RegExp; message: string }
  }
  options?: ISelectOption[]
}

export interface IInputConfigurationPartial {
  type?: string
  subtype?: string
  defaultValue?: string | number | boolean
  name: string
  label?: string
  className?: string
  placeholder?: string
  required?: boolean
  showLabel?: boolean
  validations?: {
    required?: { value: boolean; message: string }
    minLength?: { value: number; message: string }
    maxLength?: { value: number; message: string }
    pattern?: { value: RegExp; message: string }
  }
  options?: ISelectOption[]
}

export type InputName =
  | 'full_name'
  | 'name'
  | 'last_name'
  | 'company'
  | 'position'
  | 'phone'
  | 'fix_number_phone'
  | 'email'
  | 'message'
  | 'reason'
  | 'residence'
  | 'accept_terms'
  | 'cv_resume'
  | 'salary_expectation'
  | 'identification_card'
  | 'years_of_experience'
  | 'has_more_than_2_years_of_experience'
  | 'aimed_at'
  // Ini Segob fields
  | 'employment_number'
  | 'who_will_receive_the_capacitation'
  | 'kinship'
  | 'additional_comments'
  | 'accept_terms_segob'
  | 'relative_full_name'
  | 'requested_course'
// End Segob fields

export const AvailableInputConfigurations: { [key in InputName]: IInputConfiguration } = {
  full_name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'full_name',
    label: 'Nombre Completo',
    className: 'flex-1 min-w-32',
    placeholder: 'Nombre Completo',
    required: true,
    showLabel: false,
    validations: {
      minLength: {
        value: 2,
        message: 'El nombre debe tener al menos 2 caracteres'
      },
      required: { value: true, message: 'Nombre(s) requeridos' }
    }
  },
  name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'name',
    label: 'Nombre(s)',
    className: 'flex-1 min-w-32',
    placeholder: 'Nombre(s)',
    required: true,
    showLabel: false,
    validations: {
      minLength: {
        value: 2,
        message: 'El nombre debe tener al menos 2 caracteres'
      },
      required: { value: true, message: 'Nombre(s) requeridos' }
    }
  },
  last_name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'last_name',
    label: 'Apellido(s)',
    className: 'flex-1 min-w-32',
    placeholder: 'Apellido(s)',
    required: true,
    showLabel: false,
    validations: {
      minLength: {
        value: 2,
        message: 'Debe tener al menos 2 caracteres'
      },
      required: { value: true, message: 'Apellido(s) requeridos' }
    }
  },
  company: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'company',
    label: 'Empresa',
    className: 'flex-1 min-w-32',
    placeholder: 'Empresa',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Empresa requerida' }
    }
  },
  position: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'position',
    label: 'Puesto',
    className: 'flex-1 min-w-32',
    placeholder: 'Puesto',
    required: false,
    showLabel: false,
    validations: {}
  },
  phone: {
    type: 'input',
    subtype: 'tel',
    defaultValue: '',
    name: 'phone',
    label: 'Teléfono',
    className: 'w-full min-w-full',
    placeholder: 'Teléfono',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Teléfono requerido' },
      pattern: {
        value: /^[0-9\s+]*$/,
        message: 'El teléfono no es válido'
      },
      minLength: {
        value: 12,
        message: 'El teléfono debe tener al menos 12 dígitos'
      },
      maxLength: {
        value: 16,
        message: 'El teléfono debe tener máximo 14 dígitos'
      }
    }
  },
  fix_number_phone: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'fix_number_phone',
    label: 'Número Fijo',
    className: 'flex-1 min-w-32',
    placeholder: 'Número Fijo',
    required: true,
    showLabel: false,
    validations: {
      pattern: {
        value: /^[0-9\s+]*$/,
        message: 'El teléfono no es válido'
      },
      required: { value: true, message: 'Número fijo requerido' }
    }
  },
  email: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'email',
    label: 'Correo Electrónico Corporativo',
    className: 'w-full min-w-full',
    placeholder: 'Correo Electrónico Corporativo',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Correo Electrónico requerido' },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'El correo electrónico no es válido'
      }
    }
  },
  message: {
    type: 'textarea',
    subtype: 'textarea',
    defaultValue: '',
    name: 'message',
    label: 'Mensaje',
    className: 'w-full min-w-full',
    placeholder: 'Mensaje',
    required: false,
    showLabel: false,
    validations: {}
  },
  residence: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'residence',
    label: 'Residencia',
    className: 'w-full min-w-full',
    placeholder: 'Lugar de Residencia (Ciudad / Estado)',
    required: false,
    showLabel: false,
    validations: {}
  },
  reason: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'reason',
    label: 'Tipo de Consultoría:',
    className: 'w-full min-w-full',
    placeholder: 'Tipo de Consultoría:',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Consultoría requerida' }
    },
    options: [
      {
        value: '',
        label: 'Tipo de Consultoría:'
      },
      {
        value: 'Seguridad de la Información',
        label: 'Seguridad de la Información'
      },
      {
        value: 'Ciberseeguridad',
        label: 'Ciberseguridad'
      },
      {
        value: 'Continuidad de Negocio',
        label: 'Continuidad de Negocio'
      },
      {
        value: 'Gestión de Riesgos',
        label: 'Gestión de Riesgos'
      },
      {
        value: 'DevOps',
        label: 'DevOps'
      },
      {
        value: 'Metodologías Ágiles - Agile',
        label: 'Metodologías Ágiles - Agile'
      },
      {
        value: 'Gestión de Servicios de TI',
        label: 'Gestión de Servicios de TI'
      },
      {
        value: 'Gestión de Datos',
        label: 'Gestión de Datos'
      },
      {
        value: 'Gobierno y Cumplimiento de TI',
        label: 'Gobierno y Cumplimiento de TI'
      },
      {
        value: 'Gestión del Cambio Organizacional',
        label: 'Gestión del Cambio Organizacional'
      },
      {
        value: 'Gestión Antisoborno',
        label: 'Gestión Antisoborno'
      },
      {
        value: 'Generación de Valor Empresarial',
        label: 'Generación de Valor Empresarial'
      },
      {
        value: 'Otro',
        label: 'Otro'
      }
    ] as ISelectOption[]
  },
  accept_terms: {
    type: 'checkbox',
    subtype: 'privacy_policy',
    defaultValue: false,
    name: 'accept_terms',
    label: 'He leído la Política de Privacidad y doy mi consentimiento para el tratamiento de mis datos personales.',
    className: 'w-full min-w-full',
    placeholder: '',
    required: true,
    showLabel: true,
    validations: {
      required: { value: true, message: 'Debe aceptar la Política de Privacidad' }
    }
  },
  cv_resume: {
    type: 'input',
    subtype: 'file',
    defaultValue: undefined,
    name: 'cv_resume',
    label: 'CV',
    className: 'w-full min-w-full',
    placeholder: 'Adjuntar CV',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'El CV es requerido' }
    }
  },
  salary_expectation: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'salary_expectation',
    label: 'Salario Mensual Deseado (Después de impuesto)',
    className: 'w-full min-w-full',
    placeholder: 'Salario Mensual Deseado (Después de Impuestos)',
    required: false,
    showLabel: false,
    validations: {}
  },
  identification_card: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'identification_card',
    label: 'Número de Cédula Profesional',
    className: 'w-full min-w-full',
    placeholder: 'Número de Cédula Profesional',
    required: false,
    showLabel: false,
    validations: {}
  },
  years_of_experience: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'years_of_experience',
    label: 'Años de Experiencia en Puesto Similar',
    className: 'w-full min-w-full',
    placeholder: 'Años de Experiencia en Puesto Similar',
    required: false,
    showLabel: false,
    validations: {}
  },
  has_more_than_2_years_of_experience: {
    type: 'checkbox',
    subtype: 'has_more_than_2_years_of_experience',
    defaultValue: false,
    name: 'has_more_than_2_years_of_experience',
    label: '¿Cuenta con 2 o Más Referencias Laborales Comprobables?',
    className: 'w-full min-w-full',
    placeholder: '',
    required: false,
    showLabel: true,
    validations: {}
  },
  aimed_at: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'aimed_at',
    label: 'Este Curso es Para:',
    className: 'w-full min-w-full',
    placeholder: 'Este Curso es Para:',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Campo requerido' }
    },
    options: [
      {
        value: '',
        label: 'Este Curso es Para:'
      },
      {
        value: 'company',
        label: 'Empresa'
      },
      {
        value: 'individual',
        label: 'Individual'
      }
    ]
  },
  // Segob fields
  employment_number: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'employment_number',
    label: 'Número de Empleado',
    className: 'w-full min-w-full',
    placeholder: 'Número de Empleado',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Número de Empleado requerido' }
    }
  },
  additional_comments: {
    type: 'textarea',
    subtype: 'textarea',
    defaultValue: '',
    name: 'additional_comments',
    label: 'Comentarios Adicionales',
    className: 'w-full min-w-full',
    placeholder: 'Comentarios Adicionales',
    required: false,
    showLabel: false,
    validations: {}
  },
  accept_terms_segob: {
    type: 'checkbox',
    subtype: 'privacy_policy_segob',
    defaultValue: false,
    name: 'accept_terms_segob',
    label: 'He leído el Aviso de Privacidad y doy mi consentimiento para el tratamiento de mis datos personales.',
    className: 'w-full min-w-full',
    placeholder: '',
    required: true,
    showLabel: true,
    validations: {
      required: { value: true, message: 'Debe aceptar el Aviso de Privacidad' }
    }
  },
  who_will_receive_the_capacitation: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'who_will_receive_the_capacitation',
    label: '¿Quién va a Capacitarse?',
    className: 'w-full min-w-full',
    placeholder: '¿Quién va a Capacitarse?',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Campo requerido' }
    },
    options: [
      {
        value: '',
        label: '¿Quién va a Capacitarse?'
      },
      {
        value: 'employee',
        label: 'Empleado'
      },
      {
        value: 'relative',
        label: 'Familiar directo'
      }
    ]
  },
  // Segob fields with dependency of who will receive the capacitation
  kinship: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'kinship',
    label: 'Parentesco',
    className: 'w-full min-w-full',
    placeholder: 'Parentesco',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Campo requerido' }
    },
    options: [
      {
        value: '',
        label: 'Parentesco'
      },
      {
        value: 'Esposo (a)',
        label: 'Esposo (a)'
      },
      {
        value: 'Padre',
        label: 'Padre'
      },
      {
        value: 'Madre',
        label: 'Madre'
      },
      {
        value: 'Hermanos (as)',
        label: 'Hermanos (as)'
      },
      {
        value: 'Hijo (a)',
        label: 'Hijo (a)'
      }
    ] as ISelectOption[]
  },
  relative_full_name: {
    type: 'input',
    subtype: 'text',
    defaultValue: '',
    name: 'relative_full_name',
    label: 'Nombre Completo del Familiar Directo',
    className: 'w-full min-w-full',
    placeholder: 'Nombre Completo del Familiar Directo',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Nombre del familiar requerido' }
    }
  },
  requested_course: {
    type: 'select',
    subtype: 'select',
    defaultValue: '',
    name: 'requested_course',
    label: 'Seleccione el curso de su interés',
    className: 'w-full min-w-full',
    placeholder: 'Seleccione el curso de su interés',
    required: true,
    showLabel: false,
    validations: {
      required: { value: true, message: 'Curso requerido' }
    },
    options: [
      {
        value: '',
        label: 'Seleccione el curso de su interés'
      },
      {
        value: 'Curso Fundamentos de Hackeo Ético',
        label: 'Curso Fundamentos de Hackeo Ético'
      },
      {
        value: 'Curso Fundamentos de Seguridad de la Red',
        label: 'Curso Fundamentos de Seguridad de la Red'
      },
      {
        value: 'Curso Fundamentos de Ciberseguridad y Seguridad de TI',
        label: 'Curso Fundamentos de Ciberseguridad y Seguridad de TI'
      },
      {
        value: 'Curso Fundamentos de Seguridad de la Información basado en ISO/IEC 27001',
        label: 'Curso Fundamentos de Seguridad de la Información basado en ISO/IEC 27001'
      },
      {
        value: 'Curso Fundamentos de Gestión de Riesgos basado en ISO 31000',
        label: 'Curso Fundamentos de Gestión de Riesgos basado en ISO 31000'
      },
      {
        value: 'Curso Fundamentos de Continuidad de Negocio basado en ISO 22301',
        label: 'Curso Fundamentos de Continuidad de Negocio basado en ISO 22301'
      },
      {
        value: 'Curso Fundamentos de Gestión de Proyectos ágiles basado en Scrum',
        label: 'Curso Fundamentos de Gestión de Proyectos ágiles basado en Scrum'
      },
      {
        value: 'Curso Fundamentos de Gestión de Proyectos en Cascada',
        label: 'Curso Fundamentos de Gestión de Proyectos en Cascada'
      }
    ] as ISelectOption[]
  }
}

export type RequestedFormsConfigType = Array<InputName | IInputConfigurationPartial>
export const useMergeFormsConfig = (requestedFormsConfig: RequestedFormsConfigType) => {
  const mergedFormsConfig = requestedFormsConfig.map((formConfig: InputName | IInputConfigurationPartial) => {
    if (typeof formConfig === 'string') {
      return { ...AvailableInputConfigurations[formConfig] }
    }
    const inputName = formConfig.name as InputName
    return { ...AvailableInputConfigurations[inputName], ...formConfig }
  })
  return mergedFormsConfig
}
