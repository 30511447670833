'use client'
import FormGroup from '@/components/forms/FormGroup'
import InputGroup from '@/components/forms/InputGroup'
import { classNames } from '@/components/shared/classNames'
import React, { ChangeEvent, useState } from 'react'
import styles from './FormCheckbox.module.scss'

import InputErrorText from '@/components/forms/InputErrorText'

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string | React.ReactNode
  value: string | number
  isValid?: boolean
  errorText?: string
  required?: boolean
  inputDefaultChecked?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => any
}

const FormCheckbox = React.forwardRef(
  (
    {
      name,
      label,
      inputDefaultChecked,
      value,
      isValid = true,
      required,
      className,
      errorText,
      onChange
    }: FormInputProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event)
    }

    return (
      <FormGroup className={classNames(className, 'flex')}>
        <div>
          <input
            className={classNames('self-start', styles.checkbox, isValid ? '' : styles['checkbox--invalid'])}
            id={name}
            name={name}
            value={value}
            type="checkbox"
            defaultChecked={inputDefaultChecked ?? false}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleOnChange}
            required={required}
          />
          <InputErrorText>{errorText}</InputErrorText>
        </div>
        <label htmlFor={name}>{label}</label>
      </FormGroup>
    )
  }
)

FormCheckbox.displayName = 'FormCheckbox'

export default FormCheckbox
