export const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT ?? 'http://127.0.0.1:8000/api/'
export const API_SERVICES = {
  getAllCourses: 'v1/course/',
  getCourse: 'v1/course/{slug}/',
  createPostulation: 'v1/employments/postulation/',
  getEventsCalendar: 'v1/course/events-calendar/',
  getAvailableMonths: 'v1/course/events-calendar/available_months/',
  createCourseLead: 'v1/lead/course/',
  createRequestInfoLead: 'v1/lead/request-info/',
  createConsultancyLead: 'v1/lead/consultancy/',
  createSegobLead: 'v1/lead/segob/',
  getCourseCategory: 'v1/course/category/',
  getCertifyingEntity: 'v1/course/certifying-entity/',
  createNewsletterSubscription: 'v1/newsletter-subscription/',
  getAllConferenceEvents: 'v1/conference_manager/conference-event/',
  getConferenceEvent: 'v1/conference_manager/conference-event/{id}/',
  getCourseEventsCalendar: 'v1/course/{slug}/events-calendar/',
  createVisit: 'v1/visit/',
  getGalleryDocument: 'v1/gallery/documents/{code}/',
  pagesList: 'v1/main_pages/page/',
  getAllArticles: 'v1/blog/article/',
  getArticle: 'v1/blog/article/{slug}/'
}

export const ABOUT_US = '/nosotros'
export const ACCESSIBILITY = '/declaracion-de-accesibilidad'
export const BASES = '/bases-y-dinamicas'
export const BLOG = '/blog'
export const BLOG_ARTICLE = '/blog/articulos/:articleName'
export const CALENDAR = '/calendario-de-cursos'
export const CAREERS = '/bolsa-de-trabajo'
export const CONSULTANCY = '/consultoria'
export const CONSULTANCY_SERVICE = '/servicios/evaluacion-de-la-madurez-de-la-seguridad'
export const CONTACT = '/contacto'
export const COPYRIGHT = '/copyright'
export const COURSE_NAME = '/cursos/:courseName'
export const HEADER_PROOF = '/header_proof'
export const HOME = '/header_proof'
export const MAINTENANCE = '/mantenimiento'
export const NEXT_EVENTS = '/proximos-eventos'
export const PRIVACY_POLICY = '/politica-de-privacidad'
export const PRIVACY_POLICY_SEGOB = '/landing/segob/politica-de-privacidad'
export const PRIVACY_COOKIES = '/politica-de-cookies'
export const SITEMAP = '/mapa-de-sitio'
export const TERMS_AND_CONDITIONS = '/terminos-y-condiciones'
export const THANKS = '/gracias'
export const TRAINING = '/capacitacion'
export const WEBINAR = '/curso-gratuito-network-defense-essentials'
/* ServicesRoute */
export const BUSINESS_CONTINUITY = '/servicios/continuidad-de-negocio'
export const NO_IMAGE_URL = '/assets/images/resources/no-image-found.jpg'
export const ROUTES = {
  ABOUT_US,
  ACCESSIBILITY,
  BASES,
  BLOG,
  BLOG_ARTICLE,
  CALENDAR,
  CAREERS,
  CONSULTANCY,
  CONSULTANCY_SERVICE,
  CONTACT,
  COPYRIGHT,
  COURSE_NAME,
  HEADER_PROOF,
  HOME,
  MAINTENANCE,
  NEXT_EVENTS,
  PRIVACY_POLICY,
  PRIVACY_POLICY_SEGOB,
  PRIVACY_COOKIES,
  SITEMAP,
  TERMS_AND_CONDITIONS,
  THANKS,
  TRAINING,
  WEBINAR,
  BUSINESS_CONTINUITY
}
