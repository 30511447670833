'use client'
import { Button } from '@/components/buttons/Button'
import FormGroup from '@/components/forms/FormGroup'
import { IInputConfiguration } from '@/components/forms/InputConfigs'
import InputErrorText from '@/components/forms/InputErrorText'
import { Label } from '@/components/forms/Label'
import FormCheckbox from '@/components/inputs/FormCheckbox'
import FormInput from '@/components/inputs/FormInput'
import FormInputFile from '@/components/inputs/FormInputFile'
import FormSelect from '@/components/inputs/FormSelect'
import FormSelectPhone from '@/components/inputs/FormSelectPhone'
import FormTextarea from '@/components/inputs/FormTextarea'
import { classNames } from '@/components/shared/classNames'
import { ROUTES } from '@/constans'
import Link from 'next/link'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

const getDefaultValues = (InputsConfig: IInputConfiguration[]) => {
  return InputsConfig.reduce((acc: Record<string, any>, input) => {
    if (input.defaultValue) acc[input.name] = input.defaultValue
    else if (['input', 'textarea', 'select'].includes(input.type)) acc[input.name] = ''
    else if (input.type === 'checkbox') acc[input.name] = input.defaultValue ?? false
    return acc
  }, {})
}

const getFormGroups = (
  InputsConfig: IInputConfiguration[],
  control: any,
  register: any,
  resetField: any,
  errors: any,
  uuid = '',
  setValue: any
) => {
  return InputsConfig.map((inputForm: IInputConfiguration, index) => {
    // Especial requirement for segob forms
    if (inputForm.type === 'select' && inputForm.name === 'who_will_receive_the_capacitation') {
      // The default behavior is to show the who_will_receive_the_capacitation select, but if the user selects "relative" value, then show the kinship select input and the input for the relative_full_name */
      const [whoWillReceiveTheCapacitation, setWhoWillReceiveTheCapacitation] = useState('')
      const onChangeWhoWillReceiveTheCapacitation = (whoWillReceiveTheCapacitation: string) => {
        console.log('whoWillReceiveTheCapacitation', whoWillReceiveTheCapacitation)
        setWhoWillReceiveTheCapacitation(whoWillReceiveTheCapacitation)
        setValue('kinship', '')
        setValue('relative_full_name', '')
      }
      return (
        <React.Fragment key={index}>
          <Controller
            render={({ field }: any) => (
              <FormSelect
                {...field}
                required={inputForm.required}
                options={inputForm.options}
                type={inputForm.subtype}
                placeholder={inputForm.placeholder}
                className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
                errorText={errors[inputForm.name]?.message}
                isValid={!errors[inputForm.name]}
                onChange={value => {
                  onChangeWhoWillReceiveTheCapacitation(value)
                  field.onChange(value)
                }}
              />
            )}
            control={control}
            name={inputForm.name}
            rules={inputForm.validations}
          />
          {whoWillReceiveTheCapacitation === 'relative' && (
            <React.Fragment>
              <Controller
                render={({ field }: any) => (
                  <FormSelect
                    {...field}
                    required={inputForm.required}
                    options={[
                      {
                        value: '',
                        label: 'Parentesco'
                      },
                      {
                        value: 'Esposo (a)',
                        label: 'Esposo (a)'
                      },
                      {
                        value: 'Padre',
                        label: 'Padre'
                      },
                      {
                        value: 'Madre',
                        label: 'Madre'
                      },
                      {
                        value: 'Hermanos (as)',
                        label: 'Hermanos (as)'
                      },
                      {
                        value: 'Hijo (a)',
                        label: 'Hijo (a)'
                      }
                    ]}
                    type="select"
                    placeholder="Parentesco"
                    className={classNames(inputForm.className, errors.kinship ? 'invalid' : '')}
                    errorText={errors.kinship?.message}
                    isValid={!errors.kinship}
                  />
                )}
                control={control}
                name="kinship"
                rules={inputForm.validations}
              />
              <Controller
                render={({ field }: any) => (
                  <FormInput
                    {...field}
                    required={inputForm.required}
                    type="text"
                    placeholder="Nombre Completo del Familiar Directo"
                    className={classNames(inputForm.className, errors.relative_full_name ? 'invalid' : '')}
                    errorText={errors.relative_full_name?.message}
                    isValid={!errors.relative_full_name}
                  />
                )}
                control={control}
                name="relative_full_name"
                rules={inputForm.validations}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )
    }

    // Normal form inputs
    if (inputForm.type === 'input') {
      if (inputForm.subtype === 'tel') {
        const onChangeCountry = (country: string) => {
          setValue(`${inputForm.name}-ext-country`, country)
        }

        return (
          <React.Fragment key={index}>
            <Controller
              render={({ field }: any) => <input {...field} type="hidden" value="" />}
              control={control}
              name={`${inputForm.name}-ext-country`}
            />

            <Controller
              render={({ field }: any) => (
                <FormSelectPhone
                  {...field}
                  type={inputForm.subtype}
                  required={inputForm.required}
                  placeholder={inputForm.placeholder}
                  className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
                  errorText={errors[inputForm.name]?.message}
                  isValid={!errors[inputForm.name]}
                  onChangeCountry={onChangeCountry}
                />
              )}
              control={control}
              name={inputForm.name}
              rules={inputForm.validations}
            />
          </React.Fragment>
        )
      }
      if (inputForm.subtype === 'file') {
        return (
          <Controller
            key={index}
            render={({ field }: any) => (
              <FormInputFile
                {...field}
                resetField={resetField}
                required={inputForm.required}
                type={inputForm.subtype}
                placeholder={inputForm.placeholder}
                className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
                errorText={errors[inputForm.name]?.message}
                isValid={!errors[inputForm.name]}
              />
            )}
            control={control}
            name={inputForm.name}
            rules={inputForm.validations}
          />
        )
      }
      return (
        <Controller
          key={index}
          render={({ field }: any) => (
            <FormInput
              {...field}
              required={inputForm.required}
              type={inputForm.subtype}
              placeholder={inputForm.placeholder}
              className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
              errorText={errors[inputForm.name]?.message}
              isValid={!errors[inputForm.name]}
            />
          )}
          control={control}
          name={inputForm.name}
          rules={inputForm.validations}
        />
      )
    }

    if (inputForm.type === 'checkbox') {
      if (['privacy_policy_segob'].includes(inputForm.subtype)) {
        const privacyPolicyLink = (
          <Link
            href={ROUTES.PRIVACY_POLICY_SEGOB}
            target="_blank"
            className="text-blue-dark font-semibold hover:underline"
          >
            Política de Privacidad
          </Link>
        )
        const privacyPolicyText = (
          <p className="text-[14px] text-gray-dark">He leído y acepto la {privacyPolicyLink} </p>
        )
        return (
          <Controller
            key={index}
            render={({ field }: any) => (
              <FormGroup className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}>
                <Label htmlFor={inputForm.name + uuid} className="flex gap-x-2">
                  <input
                    type="checkbox"
                    checked={field.value}
                    id={inputForm.name + uuid}
                    {...field}
                    className={classNames('translate-y-[0.3rem] self-start', errors[inputForm.name] ? 'invalid' : '')}
                  />{' '}
                  {privacyPolicyText}
                </Label>
                <InputErrorText>{errors[inputForm.name]?.message}</InputErrorText>
              </FormGroup>
            )}
            control={control}
            name={inputForm.name}
            rules={inputForm.validations}
          />
        )
      }
    }
    if (inputForm.type === 'checkbox') {
      if (['privacy_policy', 'privacy_policy_short'].includes(inputForm.subtype)) {
        const privacyPolicyLink = (
          <Link href={ROUTES.PRIVACY_POLICY} target="_blank" className="text-blue-dark font-semibold hover:underline">
            Política de Privacidad
          </Link>
        )
        const privacyPolicyText =
          inputForm.subtype === 'privacy_policy' ? (
            <p className="text-[14px] text-gray-dark">
              He leído la {privacyPolicyLink} y doy mi consentimiento para el tratamiento de mis datos personales.
            </p>
          ) : (
            <p className="text-[14px] text-gray-dark">He leído y acepto la {privacyPolicyLink} </p>
          )
        const shortId = inputForm.subtype === 'privacy_policy_short' ? '-short-' : ''
        return (
          <Controller
            key={index}
            render={({ field }: any) => (
              <FormGroup className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}>
                <Label htmlFor={inputForm.name + shortId + uuid} className="flex gap-x-2">
                  <input
                    type="checkbox"
                    checked={field.value}
                    id={inputForm.name + shortId + uuid}
                    {...field}
                    className={classNames('translate-y-[0.3rem] self-start', errors[inputForm.name] ? 'invalid' : '')}
                  />{' '}
                  {privacyPolicyText}
                </Label>
                <InputErrorText>{errors[inputForm.name]?.message}</InputErrorText>
              </FormGroup>
            )}
            control={control}
            name={inputForm.name}
            rules={inputForm.validations}
          />
        )
      }
      return (
        <Controller
          key={index}
          render={({ field }: any) => (
            <FormCheckbox
              {...field}
              label={inputForm.label}
              required={inputForm.required}
              type={inputForm.subtype}
              placeholder={inputForm.placeholder}
              className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
              errorText={errors[inputForm.name]?.message}
              isValid={!errors[inputForm.name]}
            />
          )}
          control={control}
          name={inputForm.name}
          rules={inputForm.validations}
        />
      )
    }

    if (inputForm.type === 'textarea') {
      return (
        <Controller
          key={index}
          render={({ field }: any) => (
            <FormTextarea
              {...field}
              required={inputForm.required}
              type={inputForm.subtype}
              placeholder={inputForm.placeholder}
              className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
              errorText={errors[inputForm.name]?.message}
              isValid={!errors[inputForm.name]}
            />
          )}
          control={control}
          name={inputForm.name}
          rules={inputForm.validations}
        />
      )
    }

    if (inputForm.type === 'select') {
      return (
        <Controller
          key={index}
          render={({ field }: any) => (
            <FormSelect
              {...field}
              required={inputForm.required}
              options={inputForm.options}
              type={inputForm.subtype}
              placeholder={inputForm.placeholder}
              className={classNames(inputForm.className, errors[inputForm.name] ? 'invalid' : '')}
              errorText={errors[inputForm.name]?.message}
              isValid={!errors[inputForm.name]}
            />
          )}
          control={control}
          name={inputForm.name}
          rules={inputForm.validations}
        />
      )
    }
    return null
  })
}

const FormStyled = ({
  InputsConfig,
  onSubmit,
  onCancel,
  submitText,
  className = '',
  submitClassName = '',
  cancelMessage = '',
  cancelClassName = '',
  uuid = '',
  enableRecaptcha = false
}: {
  InputsConfig: IInputConfiguration[]
  onSubmit: any
  onCancel?: any
  submitText: string
  className?: string
  submitClassName?: string
  cancelMessage?: string
  cancelClassName?: string
  uuid?: string
  enableRecaptcha?: boolean
}) => {
  const defaultValues = getDefaultValues(InputsConfig)
  const {
    handleSubmit,
    register,
    reset,
    resetField,
    control,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues })
  const NEXT_PUBLIC_RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY
  const handleOnSubmit = async (data: Record<string, any>) => {
    if (enableRecaptcha) {
      // @ts-expect-error grecaptcha is loaded in the layout with the recaptcha script
      const recaptcha = await grecaptcha.enterprise.execute(NEXT_PUBLIC_RECAPTCHA_SITE_KEY, {
        action: 'contact'
      })
      onSubmit({ ...data, recaptcha }, reset)
    } else {
      onSubmit(data, reset)
    }
  }
  const formGroups = getFormGroups(InputsConfig, control, register, resetField, errors, uuid, setValue)
  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form className={`flex flex-wrap gap-x-6 gap-y-5 ${className}`} onSubmit={handleSubmit(handleOnSubmit)}>
      {formGroups}
      <div className="w-full flex flex-col gap-3 py-3">
        <Button theme="primary-dark" className={classNames('font-bold', submitClassName)} type="submit">
          {submitText}
        </Button>
        {cancelMessage && (
          <Button theme="primary-outline" className={cancelClassName} type="button" onClick={onCancel}>
            {cancelMessage}
          </Button>
        )}
      </div>
    </form>
  )
}

export default FormStyled

const randomId = () => Math.random().toString(36).substring(7)
