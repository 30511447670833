'use client'
import React, { ChangeEvent, useState } from 'react'
import style from './FormInput.module.scss'
import FormGroup from '@/components/forms/FormGroup'
import { Label } from '@/components/forms/Label'
import InputGroup from '@/components/forms/InputGroup'
import { classNames } from '@/components/shared/classNames'

import InputErrorText from '@/components/forms/InputErrorText'
import { Icon } from '@/components/icons/Icon'

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string | React.ReactNode
  type:
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'tel'
    | 'url'
    | 'search'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week'
  placeholder: string
  value: string | number
  isValid?: boolean
  errorText?: string
  required?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => any
  disablePaste?: boolean
}

const FormInput = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      type,
      value,
      isValid = true,
      required,
      className,
      errorText,
      onChange,
      disablePaste = false
    }: FormInputProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const [showPass, setShowPass] = useState(false)

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event)
    }

    const isPassword = type === 'password'
    const inputType = type === 'password' && showPass ? 'text' : type

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      if (disablePaste) {
        e.preventDefault()
      }
    }
    return (
      <FormGroup className={className}>
        <Label isFocused={isFocused} isValid={isValid} hidden={Boolean(label)} htmlFor={name}>
          {label}
        </Label>
        <InputGroup>
          <input
            className={classNames(style.input, isValid ? '' : style['input--invalid'])}
            // id={name}
            name={name}
            autoComplete="off"
            value={value}
            type={inputType}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleOnChange}
            required={required}
            placeholder={isFocused ? '' : required ? '* ' + placeholder : placeholder}
            onPaste={handleOnPaste}
          />
          {isPassword && (
            <button
              className={style['input--toggle-password']}
              onClick={() => {
                setShowPass(!showPass)
              }}
            >
              <Icon name={showPass ? 'eyeSlash' : 'eye'} title={showPass ? 'Ocultar' : 'Mostrar'} />
            </button>
          )}
          <InputErrorText>{errorText}</InputErrorText>
        </InputGroup>
      </FormGroup>
    )
  }
)

FormInput.displayName = 'FormInput'
export default FormInput
